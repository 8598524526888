const startingRoster = [
    {
        name:"Sir Alex",
        description:"Sir Alex is part of the new generation of romanian Djs,rising through the music scene with his high energy grooves and explosive DJ sets.Being one of residents at BABYLON events,he developed his own distinctive sound based out of his influences and passions, thing that has made him a favorite among fans. He played music in several places,suchas Kristal Club, Gradina Monteoru, Shade Events, Love Life Events, Rave Timisoara, High Waves Sounds Cluj, Gatsby Timisoara, ilCharlie Iasi and much more. He has delighted us throughout his career with songs loved by his fans, songs like “Ala ala ala“, “MiddleChild“, “Pure honey“ and many others.", 
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1716825430/blackWhite/photo_5956372156784951746_y-modified_bijflc.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1818131970&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        socialMedia:{
            instagram:"https://www.instagram.com/sir____alex____/",
            tiktok:"https://www.tiktok.com/@siralex151",
        },
        id: 1
    },
    {
    name:"Manolaco",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1718018061/photo_5996778968587551497_y_qr2wuw.jpg",
        description:"Manolaco is an esteemed DJ and producer from Athens, Greece, being resident at Blend. He discovered his passion for music at the age of 17 and has since dedicated his life to it. With an impressive career spanning over two decades, Manolaco has established himself as a prominent figure in the music scene. He had the privilege of sharing the stage with the biggest names in the industry such as Marco Carola, Loco Dice, Richie Hawtin, Luciano, Ricardo Villalobos, Black Coffee or Solomun, just to name a few. His productions have found homes on impressive labels such as Nervous, Moan, Rush, Sanity, Clarisse, Rebellion, and many many more.",
        spotifyLink:"https://open.spotify.com/embed/artist/4x5GoGU0k0q1jOrb95scqZ?utm_source=generator",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1386338338&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        beatportLink:"https://embed.beatport.com/?id=4095065&type=release",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/track=1880798922/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        bandcampLink2:"https://bandcamp.com/EmbeddedPlayer/track=3094256440/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        socialMedia:{
            instagram:"https://www.instagram.com/manolaco_ofc/",
            facebook:"https://www.facebook.com/manolaco.official",
            youtube:"https://www.youtube.com/c/Manolaco",
            tiktok:"https://www.tiktok.com/@manolaco.official"
        },
        id: 4
    },
    /*{
        name:"Shaf Huse",
        description:"Shaf Huse is a producer and DJ from Milan, being resident at Too long. One of his biggest hits, Mavado caused a sensation in Ibiza last year. Support came by none other than Luciano, Loco Dice and The Martinez Brothers, following other tracks like “Get Em Up”, “Go“ or “Power To The People” with which he has attracted a lot of attention. He played in many venues,all around the globe, such as London, North, Amsterdam, Santiago and Romania.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1714705750/blackWhite/Untitled_Project_8_zotjla.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1746508524&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        beatportLink:"https://embed.beatport.com/?id=4130448&type=release",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/album=3189857832/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=141248220/transparent=true/",
        bandcampLink2:"https://bandcamp.com/EmbeddedPlayer/album=1714570852/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        socialMedia:{
            instagram:"https://www.instagram.com/shafhuse/",
            facebook:"https://www.facebook.com/shafhuse/events/",
            tiktok:"https://www.tiktok.com/@shafhuse",
            youtube:"https://www.youtube.com/@shafhusemusic",
        },
        id:6
    },*/
    /*{
        name:"Andreea Ndr",
        description:"Andreea NDR is a new romanian DJ discovered by our agency. Her career started recently through her debut at BABYLON Events. She will create a memmorable musical journey for you. Her strong points being her passion and her joyful energy with lots of vibes.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1714701074/blackWhite/Untitled_Project_1_nrgxw8.jpg",
        socialMedia:{
            instagram:"https://www.instagram.com/andreea.ndr6/",
        },
        id:7
    },*/
    {
        name:"Governor",
        
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1714700872/blackWhite/iw0bgz25y9ef1w76zsdq.jpg",
        description:"Driven by his deep passion for music, Governor is a romanian DJ and producer that evolved from a music lover to a party resident at Babylon Events, creating and maintaining a memorable party atmosphere. His pursuit of excellence and collaborative spirit define his creative approach. Besides providing music for events/festivals (Pe Coclauri, UNDRGRD, Love Life Events, Shade Events, BABYLON Events), he also mixed live music on local radio stations, such as Extravaganza Radio, Camara Radio and Radio Deea.He teamed up with a diverse range of exceptional artists, forging musical connections that include Shaf Huse, Sllash and Doppe, Dobrikan, Aydee, The Gang Raw, Christian Lepah, Alex Cicada, Pol, Cezar Stefan, Sir Alex, Rhem,Adrian Eftimie and Tulvan. His goal is to make you groove and keep you hooked.",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1468248289&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        socialMedia:{
            instagram:"https://www.instagram.com/governor.music/",
            youtube:"https://www.youtube.com/c/SunGovernor",
            tiktok:"https://www.tiktok.com/@governor.music?is_from_webapp=1&sender_device=pc",
            facebook:"https://www.facebook.com/Governor.Music.Official",
        },
        id:8
    },
    {
        name:"Vladutz",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1716825921/photo_5956372156784951762_x-modified_msphj2.jpg",
        description:"Vladutz is a young promise DJ from Romania discovered by us. His passion started in 2016 and since then he has been spinning records focusing on electronic music styles reinterpreted by the Romanian (ro-minimal) groove, recently combining minimal tech with Romanian percussion and voices, becoming a resident for BABYLON Events.",
        socialMedia:{
            instagram:"https://www.instagram.com/1vladutz_/",
        },
        id:9
    },
    {
        name:"Hutu Music",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1716827164/image00003-modified_pznksi.jpg",
        description:"HUTU is a Dutch-based DJ and Producer born and raised in ‘Rotterdam. He played venues as, Toffler, Now&Wow, Factory010, Madam and festivals like Blijdorp and Expedition, where he and his friends hosted they’re own stage named “Klein Berlijn”.   HUTU is not only a part of the scene, but he is also getting known for his releases on Kubbo records, Unnamed & Unknown, BNN, Distance and his upcoming release on NoZzo. His productions are known for its Rolling basslines, Filthy percussion and energetic vocals. And his music is being supported by some of the big players like, Joseph Capriati, Franky Rizardo, Tomi&Kesh and Manda Moore just to name a few.",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1762553148&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        beatportLink:"https://embed.beatport.com/?id=4421623&type=release",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/album=3341262987/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        socialMedia:{
            instagram:"https://www.instagram.com/hutu.music/",
            tiktok:"https://www.tiktok.com/@hutumusic",

        },
        id:10
    },
    {
        name:"Blackyassine",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1716900356/acgyq6lrura6lpviqlkb.jpg",
        description:'Blackyassine is a DJ and producer,born in Bologna,to a tunisian family.He became pasionate about electronic music  from a young age. Over the years,he moved to London where he started playing in globally known clubs like Ministry of Sound,E1,Egg London,Orange Yard and many more. Throughout his career,he has delighted us with songs cherished by his fans,including hits like "Get It Right","Dime to stile","Confetti".',
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/801195034&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        socialMedia:{
            instagram:"https://www.instagram.com/blackyassineuk?igsh=d3g0OXF6MDM1d3Fp&utm_sourc%20e=qr.",
            tiktok:"https://www.tiktok.com/@blackyassine_"
        },
        id:11
    },
    {
        name:"Cotoraci",
        description:'Master in manipulating sound, Cotoraci stands out as one of the most well-established Romanian artists in his domain. Born in Arad, he first encountered music at the tender age of five through piano lessons. In 1996, he embarked on a journey that would lead to a long-lasting DJ career in an open format. Starting in 2005, he also showcased his talent as an MC, which brought him to Bucharest and saw him headlining every major club in the country. In 2018, he chose to narrow his focus to electronic music and shifted his efforts to the studio, producing his own tracks("Falcon","Sin control","Muevelo") which have gained support from key figures in the industry,such as Paco Osuna,Joseph Capriati,Marco Carola and many more.As a result, he was able to be present at international music festivals, clubs, and events relevant to his area of expertise.',
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1716902609/176A8776-modified_nl50t0.jpg",
        spotifyLink:"https://open.spotify.com/embed/artist/4BlWtG1Dq7AMgdgNMt97ID?utm_source=generator&theme=0",
        beatportLink:"https://embed.beatport.com/?id=4008215&type=release",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/track=1637871591/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        bandcampLink2:"https://bandcamp.com/EmbeddedPlayer/track=1095657903/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1413871318&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        socialMedia:{
            youtube:"https://www.youtube.com/channel/UCzmSBa3hz2GuXoUCX1XY32Q",
            instagram:"https://www.instagram.com/cotoraci888/",
            facebook:"https://www.facebook.com/McExcite/?locale=ro_RO"
        },
        id:12,
    },
    {
        name:"EFI",
        description:"Driven by a passion for music from a young age, Efi is a new romanian DJ that made his debut at BABYLON Events.His passion started in 2016 and he has been spinning records with a focus on tech-house, minimal and deep tech since then.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1718010343/photo_5931600825531089618_y-modified_ztd804.jpg",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/album=1639395639/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        bandcampLink2:"https://bandcamp.com/EmbeddedPlayer/album=1639395639/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=2890227038/transparent=true/",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/908346748&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        socialMedia:{
            instagram:"https://www.instagram.com/efisounds/",
            facebook:"https://www.facebook.com/efisounds/",
        },
        id:13,
    },
    {
        name:"Rin F",
        description:"Rin F is a Romanian DJ and producer that has been in contact with music from a very young age. From Tech-House to Deep-Tech and Minimal, he evolved very fast from the underground after-parties of Romania's clubbing capital to play in big clubs and events, such as Burning Man, Inside Festival, Kristal Club and Kudos Beach and in Florence and Rome, Italy. He has played next to artists like Pirupa, Neverdogs, Dennis Cruz, Andre Buljat and Reelow amongst others. In a very short tine, he has come a long way not only as a DJ but also a producer, with many releases, such as 'Morning Sunrise', 'Sweets For You' and many more.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1718190640/photo_6001133958051512470_y_kp8bs3.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/559863402&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        beatportLink:"https://embed.beatport.com/?id=4508833&type=release",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/album=1410417102/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=2328563594/transparent=true/",
        socialMedia:{
            instagram:"https://www.instagram.com/rin_.f/",
            facebook:"https://www.facebook.com/rin.dj0",
            tiktok:"https://www.tiktok.com/@rin_.f",
            youtube:"https://www.youtube.com/@rinf159"
        },
        id:14
    },
    {
        name:"Alexandrescu",
        description:"Alexandrescu is a Romanian DJ, founder of Rockstar Collective and mastermind behind the Burning Man camp Vampire Empire. Known for his for his euphoric sets that meld vibrant rhythms with the soul of house music, he captured the hearts of his audience. His sets are not just performances, they are gatherings of kindred spirits united under the banner of profound beats and electrifying melodies.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1718195470/photo_6003385757865198251_m_b94m55.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1629869115&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        socialMedia:{
            instagram:"https://www.instagram.com/alexandrescu667/",
            facebook:"https://www.facebook.com/alexandrescu667",
            tiktok:"https://www.tiktok.com/@alexandrescu667?_t=8n8a11i7QfY&_r=1"
        },
        id:15
    },
    {
        name:"KAISSER",
        description:"'Just a strange guy from the mountains' he says. But this is a merely description that reveal us just a small part of his origins and his spirit. In a short history, his real passion for music, was planted at a young age by his elder brother, when he received a recorded cassette tape of Sepultura. Since then, he attended every rock concert until 1995. In the early 2000s his passion for 'Rage Against The Machine', 'Beastie Boys' & 'Die Krupps' paved the road to what will become a wide collection of 'FUTURE Sound of London', 'Orbital', 'The Orb' & 'Underworld'. Which will set him on the path of electronic music. Always present in the first electronic music clubs opened Bucharest like Studio Martin, Web Club, Karma and Zerillos. In 2001 he was driven to buy his first turntables and went from being a fan of music to a passionate, real Dj with the help of Dj Kool and Dj Demos, and since those times, his passion for electronic music evolved to tech house, deep house, micro house and techno. To which he remained faithful. Now, his productions and sets are a strange genuine voyage into his own cosmic universe, where that huge void is in fact filled with stars transposed into dark sounds, and every planet is a combination between his music culture, passion and hard work. Always drawn by atypical textures, he manages to 'vocalize' his own experiences by leading his crowd into his void. Somehow, all of that combined, resulting into an uplifting state of mind. His adventurous spirit transcends into the sets he plays, where we can make an ambitious comparison with the Haydn's 'Surprise', introducing all kinds of unlikely elements such as old-school drum and bass or classical music. Throughout his career he played along: Keny Larkin, John Creamer, Art Department, Ellen Allien, Francesca Lombardo, Monika Kruse, Nicolas Lutz, Christian Burkhardt, Steve Lawler, Livio and Roby, kozo, Sasha Dive, Audiofly, lo Mullen, Ion Ludwig and many more.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1714705960/blackWhite/f1nopvyssjpw3hyjdjzj.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/595223718&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        socialMedia:{
            youtube:"https://www.youtube.com/channel/UCYAkY21n78EJnbmbHK7bCdQ",
            instagram:"https://www.instagram.com/kaisser.music/",
            facebook:"https://www.facebook.com/profile.php?id=61555082482120"
        },
        id:16
    },
    {
        name:"Gioko",
        description:"Gioko is a romanian DJ and producer. His passion stared in 2017,and since then he invested a lot of time and  effort in developing his mixing skills, experimenting with different styles and sounds to create unique and captivating mixes.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1720642574/blackWhite/WhatsApp_Image_2024-07-10_at_16.52.00_37d86988_qql6vg.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1809665412&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        socialMedia:{
            instagram:"https://www.instagram.com/gioko__/",
            facebook:"https://www.facebook.com/Giokodj",
            youtube:"https://www.youtube.com/watch?v=OFIvnYjNSNw",
        },
        id:17
    },
    {
        name:"GRIG",
        id:18,
        description:"GRIG is a DJ  and producer from Cluj-Napoca, the city where Untold was born, where everything started. At the age of 27, he managed to raise a beautiful community, at Line Up Romania, he owns a label called CLUJANGELES and he is eventplaner for Take Notes and Nozzo labels in Cluj Napoca. His purpose is to share his feelings and thougts through music,that lead to colaborations with brands, for example, Trend Him, Vitamin Go, 18 GYM and Sense4Fit. His journey started like every child’s who had a dream. Grig was lucky to have supportive friends who stand by him and gave him confidence. Over time, he shared the stage with artists that he admired, Tomi and Kesh, Neverdogs, Cosmin Horatiu, Pablo Luque, Proudly People, Saul Antolin, Franky Rizardo, Ale de Tuglie, Nic Fanciulli, Ida Engberg, Mahony, Chick’s Luv Us and many others. He performed on Extravaganza Radio, on many places like ELECTRIC CASTLE FESTIVAL, CODRU FESTIVAL, SPORTSFESTIVAL, SENSE4FIT ( the biggest summit in Europe ), DUPAYA ( SAGA AFTERPARTY ) , LINE UP ( UNTOLD AFTERPARTY) and he was invited to Black Sea, being the warmup for Cuartero. He was the winner of Electric Castle’s Dj Contest and the 2nd winner of Untold Festival’s Dj Contest. In present he is working on his own production, his last official project “DAMN BABY” played by Denis Cruz in Space Miami was on top 32 in the world according to beatport.com and and his track “FREAK” was played by Marco Carola b2b Solomun in Pacha Ibiza.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1722361842/IMG_6736-modified_pdobcl.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1811708889&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        beatportLink:"https://embed.beatport.com/?id=3617449&type=release",
        socialMedia:{
            facebook:"https://www.facebook.com/grigofc/",
            instagram:"https://www.instagram.com/grigmihai/?hl=es",
            youtube:"https://www.youtube.com/watch?v=YswEea5Lrjg"
        }
    },
    {
        name:"PoL",
        id:19,
        description:"PoL, by his real name Paul Vasilescu was born in the heart of Bucharest – Romania on 11-06-1986 and still, his heart is where he mixes, his soul is for the people. His curiosity about music began in a morning when he attended his first after hour party at a very well-known club in Bucharest named Silver then arrived home and started to look for software that could help him understand how it’s done and self-learned the basics. After building his character he started putting his feelings in the music he played slowly improving his technical abilities and favorite music genre. He began his DJ career in 2010 and in 2012 he became DJ resident at FlyBoard Snagov. In the same year he became Resident DJ at Pop Bar by Van Gogh. 2015 was the year when he started the residency at MooD Snagov and Sankt Petersburg Pub where he hosted a party every monday for about two years. 2016 is the year of new opportunities and besides the existing residencies he is hosting Tech Whispers, a weekly live show at Radio DEEA and a new residency at Privilege Sound party hosted by Jack’s Pub. Starting 2017 he is part of We Are Golan project as one of the resident djs and event manager. His favorite genres are very diverse from funky house, nu-disco to deep-tech, techno, progresive and also minimal and micro, he played together with well-known local DJs like: Negru, Adrian Eftimie, Optick, Rosario Internullo, Victor Stancov, Marco Briguglia, Just2, Dobrikan, Dubphone, Luca M, Marika, Andre Rizo, Bibi, Gully, Diego, Double Deez, Alex Dutu, Alex T, The Gang, Christian Lepah, No Parachute also he had the opportunity to play together with international DJs like SIS, Ninetoes, Jose Maria Ramon, Neverdogs,Jerry Ropero and Horatio, HVMZA, FNX Omar. His motto is: “You can find bits and pieces of your soul in the music I play”. His favorite quote: “Life without music would be a mistake” (Fredrick Nietzsche).",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1722361880/photo_5846038243701802381_y-modified_mcz3gj.jpg",
        socialMedia:{
            instagram:"https://www.instagram.com/pol.official_page",
            facebook:"https://www.facebook.com/PoL.Official.Page"
        }
    },
    {
        name:"EMANUEL",
        id:20,
        description:"Emanuel is a Romanian DJ with interests in Funky/ Groove House, Deep House and Afro House.  He became interested in electronic music scene in early 2000 and since then he've been listening and playing music for years, a passion that has always made his life more enjoyable.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1722362075/photo_5846038243701802386_y-modified_wwlz0e.jpg",
        socialMedia:{
            instagram:"https://www.instagram.com/djemanuel_/"
        }
    },
    //{
    //    name:"HIDE THE G",
    //    id:21,
    //    image:"https://res.cloudinary.com/dsy9com82/image/upload/v1722362081/photo_5846038243701802624_y_bwaztv.jpg",
    //    description:"With a deep passion for music, HIDE THE G is a newcomer Romanian DJ, where the beats are as mysterious as the name. With a great ability to read the crowd, he transforms every event into a musical journey.",
    //    soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1801033149&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    //    socialMedia:{
    //        instagram:"https://www.instagram.com/hide.the.g/",
    //        youtube:"https://www.youtube.com/watch?v=pfVyLLk4mYQ"
    //    }
    //},
    {
        name:"Alexander Fabyann",
        id:22,
        description:"Coming from Romania, Alexander Fabyann has been active in the electronic music since the age of 20, gathering many fans and appreciation for his work. He travelled for some time to Spain and Greece, through the island of Rhodes, Crete and Palma de Mallorca, places that helped him gain experience and cultural diversity. Alexander Fabyann has been confirmed at many festivals, such as SAGA Festival, BEACH, PLEASE Festival, HUSTLE Festival (Soho Stage), DEEP FOREST FEST, SUMMER SPELL, and other promising ongoing projects(Kristal Club, Laminor Arena, Monteoru, nJoy Club, JELECTRO in London) on stage with renowed artists. Additionally, in the summer of 2023 he undertook his residency at Therme Bucharest. He approaches tech-house and techno in his sets, managing to capture beats and the overflowing energy inspired by the audience. For him, things can go only uphill, 'under the influence of good music'. ",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1724312349/WhatsApp_Image_2024-08-10_at_13.24.15-modified_nojdnj.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1842982719&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        socialMedia:{
            instagram:"https://www.instagram.com/alexanderfabyann?igsh=aTg1dWQ1YnJ2c2Y0",
            facebook:"https://www.facebook.com/alexander.fabyann",
            tiktok:"https://www.tiktok.com/@alexanderfabyann?_t=8okjgfC04Qb&_r=1"
        }
    },
    {
        name:"Ciprian",
        id:23,
        description:"Ciprian is a talented DJ and producer known for his unique blend of tech house, minimal, deep, funky, and Latin sounds. Born in Romania and now based in London, he has carved out a niche for himself in the electronic music scene with his distinctive and eclectic style. Ciprian's music is characterized by deep and penetrating basslines, funky grooves, and Latin-inspired rhythms that create a vibrant and energetic atmosphere on the dance floor. His tracks are known for their hypnotic melodies, intricate percussion, and soulful vocals, drawing listeners in and keeping them captivated from start to finish",
        image:"https://res.cloudinary.com/dqzkxvoc5/image/upload/v1725398038/WhatsApp_Image_2024-08-26_at_13.56.22-modified_rgey1y.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1835254629&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        socialMedia:{
            instagram:"https://www.instagram.com/ciprian__ro?igsh=bDZ2NWYzcGdvcm8y&utm_source=qr",
            tiktok:"https://www.tiktok.com/@ciprian__ro?_t=8lzVeT0NZIH&_r=1"
        }
    },
    {
        name:"Mike A",
        id:24,
        description:"Mike A is a romanian DJ. His journey started in pandemic, being in the music world as the manager of Cosmin Horațiu, and since then he invested a lot of time and effort in developing his mixing skills, experimenting with different styles and sounds to create captivating mixes.",
        image:"https://res.cloudinary.com/dqzkxvoc5/image/upload/v1725398037/WhatsApp_Image_2024-08-26_at_15.09.00-modified_r0wjbm.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/897527863&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        socialMedia:{
            instagarm:"https://www.instagram.com/mike.anuta?igsh=NXhtZWd1bW80c202"
        }
    }
]

startingRoster.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

module.exports = startingRoster
